@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import "./variables";
@import "./tos.table.scss";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%; /* Ensures that the body and html elements take up full height */
  overflow: hidden;
  font-family: $font-family-base !important;
}

// responsive styles

/* Mobile styles */
@media (max-width: 600px) {
  // .container {
  //   padding: 10px;
  //   flex-direction: column;
  // }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 1024px) {
  // .container {
  //   padding: 20px;
  //   flex-direction: row;
  // }
}

/* Desktop styles */
@media (min-width: 1025px) {
  // .container {
  //   padding: 40px;
  //   flex-direction: row;
  // }
}

// common styles

.icon_svg {
  width: 16.15px;
}

.full_page_container {
  width: 100%;
  height: 100%;
  // width: 100%;
  // height: 100vh;
  // overflow: hidden;
}

.login_system_bkg_cover {
  background: url("/assets/images/login-bkg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.login_card_component {
  // transition: all 250ms ease-in-out;
  display: inline-flex;
  padding: 68px 62px 48px 64px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: var(--Radius-200, 8px);
  border: 1px solid var(--Gray-100, #F2F4F7);
  background: var(--Grays-White, #FFF);

  /* Shadows/shadow-md */
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px;
}

label {
  color: $label-color;
  font-size: 13px;
  font-style: normal;
  font-weight: 350;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.required_field_label:after {
  content: " *";
  color: $error-color;
}

.filter_is_applied:after {
  content: "\25cf";
  color: $error-color;
  top: -14px;
  position: absolute;
  right: -7px;
  font-size: 23px;
}

.label_with_checkbox {
  font-weight: 600;
  font-size: 14px;
  color: $label-color;
  display: flex;
  align-items: start;
  gap: 8px;
  user-select: none;
  margin: 0;
  cursor: pointer;
}

.primary_button {
  //border-radius: 5px;
  background-color: #1062B9;
  font-weight: 500 !important;
  //height: 50px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  border-style: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.2px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 8px 12px;
  position: relative;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.primary_button:hover:not([disabled]),
.primary_button:focus:not([disabled]) {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.primary_button:hover:not([disabled]) {
  transform: translateY(-1px);
}

.primary_button:active {
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.primary_button:disabled {
  background-color: $disbaled-primary-button;
  cursor: not-allowed;
  color: var(--gray-400);
}

.primary_button_short {
  @extend .primary_button;
  min-width: 160px;
}

.danger_button {
  @extend .primary_button;
  background-color: $error-color;
}

.secondary_button {
  @extend .primary_button;
  color: $text-color;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.tertiary_button {
  @extend .primary_button;
  border: 1px solid var(--brand-300, #86BDF3);
  background: var(--brand-50, #F1F7FE);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--brand-700, #0E4E96);
  font-weight: 600 !important;
  line-height: 20px;
  app-svg-icon{
    background-color: var(--brand-700, #0E4E96);
  }
}

.ghost_button{
  @extend .primary_button;
  border-radius: 8px;
  border: 1px solid var(--Brand-300, #86BDF3);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Brand-500, #1D7EDA);
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px; /* 142.857% */
  app-svg-icon{
    background-color: var(--Brand-500, #1D7EDA);
  }
}

.table_select_all_button {
  @extend .primary_button;
  border-radius: 50px;
  background-color: rgba(25, 181, 254, 0.2);
  color: $primary-color;
  font-weight: 800;
}

.black_button {
  @extend .primary_button;
  background-color: #19303a;
  border: 1px solid #19303a;
  color: #f5f7f7;
}

.inversed_danger_button {
  @extend .primary_button;
  background-color: #e322340a;
  border: 1px solid $error-color;
  color: $error-color;
}

input {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  //line-height: 1.5;
  font-weight: 400;
  color: var(--gray-900);
  letter-spacing: 0.2px;
  align-self: stretch;
  border-radius: var(--Radius-200, 8px);
  border: 1px solid $border-color;
  background: $background-color;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  //transition: all 200ms ease-in-out;

  &::placeholder {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    font-size: 14px;
    overflow: hidden;
    color: var(--gray-400);
    text-overflow: ellipsis;
    font-weight: 300;
    line-height: 1.5;
  }

  // &:hover {
  //   border: 1px solid #1D7EDA !important;
  // }

  &::-ms-input-placeholder {
    color: $border-color;
  }

  // &:focus-visible {
  //   border: 1px solid #1D7EDA;
  // }

  &:disabled {
    background-color: var(--gray-50);
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;

    &::-ms-input-placeholder {
      color: var(--gray-200);
    }
  }
}

input[type="text"]:read-only {
  background-color: var(--gray-50);
  cursor: not-allowed;
  pointer-events: none;

  &::-ms-input-placeholder {
    color: var(--gray-200);
  }
}

input.form_error {
  border: 1px solid var(--error-300);
  // background-color: #e3003408;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #fff;
  position: relative;
  cursor: pointer;

  //transition: all 50ms ease-in-out;

  &:checked {
    border: 1px solid #1062B9;
    background: #E2EDFC;
  }

  &:checked::after {
    padding: 5px 5px 0px 5px;
    content: url('/assets/icons/tick.svg');
    width: 14px;
    height: 20px;
    //flex-shrink: 0;
  }
}

// input[type="checkbox"]:disabled {
//   background: red !important;
// }

input[type="radio"] {
  width: 16px !important;
  height: 16px !important;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  accent-color: var(--Brand-600, #1062B9);
  cursor: pointer;
}

input[type="datetime-local"] {
  font-size: 14px;
  font-family: $font-family-base;
  cursor: pointer;
}

input[type="date"] {
  font-size: 14px;
  font-family: $font-family-base;
  cursor: pointer;
}

// input[type="radio"]:checked {
//   background-color: $primary-color;
// }

// anchor tag style

a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18.23px;
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  user-select: none;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: var(--brand-200);
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .header_login {
    color: var(--Gray-900, #101828);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .sub_header {
    color: var(--Gray-800, #182230);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.text_align_center {
  text-align: center;
}

.centered_flex_container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.spaced_flex_container {
  display: flex;
  justify-content: space-between;
}

.flex_align_center {
  align-items: center;
}

.flex_justify_end {
  justify-content: end;
}

.error_color {
  color: $error-color !important;
}

.hint_msg {
  font-size: 14px;
  font-weight: 500;
  color: $label-color;
}

@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.append_animation {
  animation: append-animate 0.3s linear;
}

.display-contents {
  display: contents;
}

.custom-tooltip {
  position: absolute;
  background-color: #001925;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  white-space: pre-line;
  z-index: 1000;
  box-shadow: 0px 5px 10px 0px rgba(0, 25, 37, 0.4);

  div {
    padding: 10px 15px;
  }

  div:not(:last-child) {
    border-bottom: solid 1px #19303a;
  }
}

.cdk-overlay-connected-position-bounding-box {
  right: 80px !important;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    padding: 0;
    background-color: #fff;
    border: 1px solid var(--gray-700);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    .mat-mdc-menu-item {
      height: 36px;
      padding: 0 15px;
      background: var(--gray-900);
      color: var(--gray-100) !important;
      font-size: 14px !important;

      &:not(:last-child) {
        //border-bottom: 1px solid var(--gray-800);
      }

      &:hover {
        background: var(--gray-800);
      }

      .mat-mdc-menu-item-text {
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.full-width {
  width: 100% !important;
}

//disabled all material ripples
.mat-ripple {
  display: none;
}

.form_validation_error {
  color: $error-color;
  position: relative;
  //top: -22px;
}

// .form_validation_error_new {
//   color: $error-color;
//   position: relative;
//   visibility: hidden;
// }
// .form_validation_error_new.visible {
//   visibility: visible;
// }

.form_validation_error, .form_validation_error_new {
  color: $error-color;
  position: relative;
  padding-left: 2px;
  opacity: 0;
  height: 1em;
  transition: opacity 0.3s ease-in-out;
}

.form_validation_error.form_error, .form_validation_error_new.visible {
  opacity: 1;
}

select {
  &:hover {
    background-color: #f5f7f7;
  }

  cursor: pointer;
  height: 40px;
  padding: 8px 10px;
  border-radius: 8px;
  //: 1px solid #cbd2d9;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-900);
  background: url("/assets/icons/select_down_arrow.svg") no-repeat #fff;
  background-position: calc(100% - 0.75rem) center !important;
  border: 1px solid $border-color;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 30px !important;

  &:focus-visible {
    border: 1px solid $primary-color;
  }

  &:invalid {
    color: var(--gray-500);
    font-weight: 100;
  }
}

select:disabled, select.read-only {
  opacity: 1;

  &:hover {
    background-color: none;
  }

  border: 1px solid var(--gray-200);
  background: no-repeat #F9FAFB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default;
  pointer-events: none;
}

textarea {
  padding: 15px;
  border-radius: 8px;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid $border-color;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    font-weight: 400 !important;
    color: var(--gray-500);
    opacity: 0.7; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: $border-color;
  }

  &:focus-visible {
    border-color: transparent;
    outline-color: $primary-color;
  }
}

// ::ng-deep .mat-select-trigger {
//   color: #3f51b5;
//   font-weight: bold;
//   background-color: #f0f0f0;
//   border-radius: 4px;
//   padding: 8px;
// }

// ::ng-deep .mat-select-panel {
//   background-color: #ffffff;
//   border: 1px solid #3f51b5;
//   max-height: 200px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// }

// ::ng-deep .mat-option {
//   color: #333333;
//   font-size: 14px;
//   padding: 12px;
// }

// ::ng-deep .mat-option:hover {
//   background-color: #eeeeee;
// }

// ::ng-deep .mat-option.mat-selected {
//   background-color: #3f51b5;
//   color: #ffffff;
// }

.mat-mdc-select-trigger {
  color: $text-color !important;
  font-size: 16px !important;
}

.mat-mdc-text-field-wrapper {
  &:focus-visible {
    outline: #19b5fe auto 1px !important;
  }
}

.mat-mdc-select-panel {
  padding: 0 !important;
  background: #fff !important;

  .mat-mdc-option {
    height: 50px !important;
    border-bottom: 1px solid #f5f7f7 !important;
    font-weight: 500 !important;

    .mdc-list-item__primary-text {
      color: $text-color !important;
      font-size: 13px !important;
    }
  }

  .mat-pseudo-checkbox-full {
    border-color: #cbd2d9 !important;
    border-width: 1px !important;
    border-style: solid !important;
  }

  .mat-pseudo-checkbox {
    background-color: #f5f7f7 !important;
  }

  .mat-pseudo-checkbox-checked {
    background-color: #19b5fe !important;
  }
}

.header_with_back_button {
  .back_button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  gap: 20px;

  app-svg-icon {
    display: flex;
    width: 27px;
    height: 27px;
    background: $icon-dark;
  }
}

.mdc-linear-progress__bar-inner {
  border-color: $primary-color !important;
}

[hidden] {
  display: none !important;
}

// suggestion component style overrides

app-suggestion-dropdown {
  .ng-select.ng-select-single .ng-select-container {
    height: 50px;
    border-radius: 3px;
    // border-right: none;
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: solid 1px $border-color;
    box-shadow: none;
  }

  ng-select > input {
    margin: 0;
  }

  ng-select:focus-visible {
    outline: none !important;
  }

  .ng-value {
    .ng-value-label {
      font-size: 15px;
      font-weight: 600;
      color: $text-color;
    }
  }

  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input {
    top: 0 !important;
    outline: none !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: $text-color;
    font-weight: 500;
    padding: 8px 10px;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #cbd2d9;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #eef0f2;
  }

  .ng-option-selected {
    background-color: rgba(25, 181, 254, 0.2);
  }
}

mark {
  background: #1bb5fe29;
}

.divider {
  height: 1px;
  align-self: stretch;
  background: var(--gray-200);
  width: 100%;
}

.verticle-divider {
  width: 1px;
  align-self: stretch;
  background: var(--gray-200);
  height: 100%;
}

.chip {
  cursor: default;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #EFF8FF;
  color: $primary-color;
  padding: 0 10px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid $primary-color

  app-svg-icon {
    width: 8px;
    height: 8px;
    background: $primary-color;
    cursor: pointer;
  }
}

.table-view-data  {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 741px;
  border: 1px solid var(--gray-200);
  text-align: left;

  thead {
      background: var(--gray-50);
      tr:first-child th:first-child {
          border-top-left-radius: 8px;
      }
      tr:first-child th:last-child {
          border-top-right-radius: 8px;
      }
  }

  tbody {
      tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }
      tr:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }
      tr:last-child td {
          border-bottom: none;
      }
  }


  td {
      padding: 12px 24px;
      font-size: 13px;
      color: var(--gray-600);
      font-weight: 400;
      line-height: 20px;
      border-bottom: 1px solid var(--gray-200);
  }

  th {
      padding: 12px 24px;
      gap: 12px;
      font-size: 12px;
      color: var(--gray-600);
      font-weight: 500;
      line-height: 1.5;
      border-bottom: 1px solid var(--gray-200)
  }

  tbody tr .primary_column {
    color: var(--gray-700);
    font-weight: 500;
    line-height: 20px;
  }

  tr .delete_column {
    padding: 2px 16px;
  }

  tr:hover {
      background-color: var(--gray-100);
  }

  th,
  td {
      min-width: 50px;
  }

  th:first-child,
  td:first-child,
  th:last-child,
  td:last-child {
      min-width: auto;
      width: auto;
  }
}

.horizontal_tabs {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: -0.5px;

  .tabs {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    //flex-wrap: wrap;
  }

  .tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 100ms ease;
  }

  .tab_name {
    display: flex;
    padding: 0px 4px 8px 4px;
    justify-content: center;
    align-items: center;
    gap: var(--Space-200, 8px);
    color: var(--gray-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .tab_name_active {
    color: #1062B9;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 142.857% */
  }

  .bottom_border {
    height: 2px;
    align-self: stretch;
  }

  .bottom_border_active {
    background: #1062B9;
  }

  .divider {
    height: 1px;
    align-self: stretch;
    background: var(--gray-200);
    width: 100%;
  }
}

.close_icon_wrapper {
  display: flex;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 22px;
  border: 1px solid var(--gray-300);
  background: var(--gray-warm-50);
  cursor: pointer;

  transition: all 100ms ease-in-out;

  &:hover {
      border: 1px solid var(--gray-400);
      background: var(--gray-warm-100);
      app-svg-icon {
          background: var(--gray-400);
      }
  }

  app-svg-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      background: var(--gray-400);
  }
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 0px;
  width: 100%;

  .left {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .right {
    display: flex;
    gap: 12px;
    align-items: flex-end;

    .btn {
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }


  .title {
    color: var(--gray-900, #101828);
    font-size: 18px;
  //   font-family: "Source Sans 3";
    font-weight: 600;
    line-height: 28px;
  }
}

.card-layout {
  display: flex;
  padding: 24px 32px 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--Gray-100, #F2F4F7);
  background: #FFF;

  ::ng-deep app-dynamic-form{
    width: 100%;
  }
}
