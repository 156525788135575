//TODO: Remove post all components are migrated to the table component

.search_bar_action_btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 16px;

  .container {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }

  .search_bar {
    display: flex;
    height: 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex-shrink: 0;

    .search_container {
      position: relative;
      display: flex;
      align-items: center;

      input[type="text"] {
        display: flex;
        padding: 10px 14px 10px 42px;
        align-items: center;
        width: 300px;
        //height: 24px;
        gap: 8px;
        font-size: 14px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #D0D5DD);
        background: var(--White, #FFF);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .search_icon {
        position: absolute;
        left: 14px; /* Adjust the position as needed */
        width: 20px; /* Adjust the size as needed */
        height: 20px; /* Adjust the size as needed */
        pointer-events: none; /* Ensure the icon does not block input interactions */
      }
    }
  }


  .filter_btn {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    border-radius: var(--Radius-200, 8px);
    height: 36px;

    button {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: var(--Space-200, 8px);
      border-radius: var(--Radius-200, 8px);
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--Grays-White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      cursor: pointer;
    }
  }

  .bulk_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .selection_count {
      .count {
        color: var(--gray-700);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 8px;


      button {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: var(--Space-200, 8px);
        border-radius: var(--Radius-200, 8px);
        border: 1px solid #1062B9;
        background: #E2EDFC;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #10437C;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
      }

      .tick {
        app-svg-icon {
          background: #a3a8b4;
          width: 16px;
        }
      }

      .close {
        app-svg-icon {
          background: #a3a8b4;
          width: 12px;
        }
      }

      .lock {
        app-svg-icon {
          background: #a3a8b4;
          width: 13px;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.page_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  margin-bottom: 16px;

  .header_content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .text_and_supporting_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;

    .page_title {
      color: var(--Gray-900, #101828);
      font-size: 18px;
      font-weight: 600;
      line-height: 38px; /* 158.333% */
    }

    .page_desc {
      color: var(--Gray-600, #475467);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .action_btn {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg, 12px);

  }

  .add_new_btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--Space-200, 8px);
    border-radius: var(--Radius-200, 8px);
    border: 1px solid #1062B9;
    background: #1062B9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--White, var(--Grays-White, #FFF));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }


}

.tos-table-template {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--gray-200, #E4E7EC);
  background: var(--Grays-White, #FFF);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

  // table {
  //   width: 100%;
  //   border-collapse: collapse;
  // }

  tbody {
    background: $background-color;
  }

  // th,
  // td {
  //   height: 54px;
  //   padding: 0px 10px;
  //   text-align: left;
  //   white-space: nowrap;
  //   width: 150px;
  // }

  // th input[type="checkbox"],
  // td input[type="checkbox"] {
  //   margin: 0px;
  // }

  .table-stats {
    display: flex;
    height: 28px;
    padding: 16px 24px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-grow: 1;

      .total-users {
        width: 114px;
        color: var(--Value, #59585B);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .active-users {
        width: 78px;
        color: #039855;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .inactive-users {
        color: #D92D20;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-grow: 0;
    }

  }

  .table-container {
    //-ms-overflow-style: none; /* Internet Explorer 10+ */
    //scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
    height: 100%;
    max-height: calc(100vh - 270px); /* Set your desired height */
    overflow: auto; /* Allow vertical scrolling */
    position: relative; /* Ensure the position context for fixed elements */
    //&::-webkit-scrollbar {
    //  width: 0px;
    //  background: transparent; /* Optional: background color */
    //}

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;

      th,
      td {
        height: 54px;
        padding: 0px 10px;
        text-align: left;
        white-space: nowrap;
        width: 150px;
      }

      tr {
        border-bottom: 1px solid var(--gray-100);
      }

      thead th {
        position: sticky; /* Makes header sticky */
        top: 0;
        border-bottom: 1px solid var(--gray-200, #E4E7EC);
        background: var(--gray-warm-100);
        z-index: 2; /* Keeps the header above body rows */
        height: 44px;
        &:hover {
          background: var(--gray-warm-200);
        }
      }

      th {
        color: var(--gray-950);
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 18px; /* 150% */
        .icon_cover {
          display: flex;
          align-items: center;
          gap: 4px;

          app-svg-icon {
            background: var(--gray-600);
            width: 16px;
            height: 16px;
          }
        }
        .resize-handle-right {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: col-resize !important;
          background-color: transparent;

          /* Make the hit area larger but invisible */
          &::after {
            content: '';
            position: absolute;
            right: -4px;
            width: 8px;
            top: 0;
            bottom: 0;
            cursor: col-resize !important;
          }
        }

        .plus_icon {
          //justify-content: center;

          app-svg-icon {
            width: 30px;
            cursor: pointer;

            &:hover {
              background: $primary-color;
            }
          }
        }
      }

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--gray-700);
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */

        .icon_cover {
          display: flex;
          padding: 2px 6px;
          align-items: center;
          gap: 4px;
          box-sizing: border-box;
          border-radius: 6px;
          color: var(--gray-700, #344054);
          text-align: left;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */

          .badge_status {
            box-shadow: var(--shadows-shadow-xs);
            border-radius: 6px;
            background-color: var(--base-white);
            border: 1px solid var(--gray-300);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 2px 6px;
            gap: 0px;
          }

          .badge_locked {
            border-radius: 16px;
            background-color: var(--gray-100);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 2px 8px 2px 6px;
            //gap: 4px;
          }

          app-svg-icon {
            display: list-item;
            position: relative;
            width: 8px;
            margin-right: 8px;
          }
        }

        .active {
          app-svg-icon {
            background: $success-color;
          }
        }

        .inactive {
          app-svg-icon {
            background: $error-color;
          }
        }

        .locked {
          app-svg-icon {
            width: 12px;
            background: #1D7EDA;
          }
        }

        .not_locked {
          app-svg-icon {
            width: 13px;
            background: var(--gray-700);
          }
        }

        .admin {
          app-svg-icon {
            width: 13px;
            background: #1D7EDA;
          }
        }

        .not_admin {
          app-svg-icon {
            width: 13px;
            background: var(--gray-700)
          }
        }

        .role_container {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .first_role {
          display: flex;
          padding: 2px var(--Space-200, 8px);
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--blue-50);
          color: var(--brand-700);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }

        .second_role {
          display: flex;
          padding: 2px var(--Space-200, 8px);
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: #EEF4FF;
          color: #3538CD;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }

        .third_role {
          display: flex;
          padding: 2px var(--Space-200, 8px);
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--Indigo-50, #EEF4FF);
          color: var(--Indigo-700, #3538CD);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }

        .role_count {
          display: flex;
          padding: 2px var(--Space-200, 8px);
          justify-content: center;
          align-items: center;
          border-radius: 16px;
          background: var(--Gray-100, #F2F4F7);
          color: var(--Gray-700, #344054);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          &:hover {
            cursor: pointer;
          }
        }

        .plus_icon {
          app-svg-icon {
            background: #a3a8b4;
            cursor: pointer;
            width: 41px;
            height: 27px;

            &:hover {
              background: $primary-color;
            }
          }
        }
      }

      .selected_row {
        background: var(--brand-50) !important;
        .table_menu_td {
          background-color: var(--brand-50) !important;
        }
      }

      tr:hover {
        background: var(--gray-50);
        // rgba(25, 181, 254, 0.2)
      }

      // tbody tr:nth-child(even):hover {
      //   //background: var(--gray-100);

      // }

      tbody tr:nth-child(even) {
        //background: var(--gray-50);
      }

      // tr:last-child {
      //   td {
      //     border: none;
      //   }
      // }

      .cb_td,
      .cb_th {
        width: 20px;
        padding: 0px 12px 0px 24px;
        text-align: center;
      }

      .sl_no_th,
      .sl_no_td {
        width: 60px;
        text-align: center;
      }

       //.name_td,
       //.name_th {
       //  // min-width: 150px;
       //  width: 150px;
       //}

      //.email_td,
      //.email_th {
      //  width: 240px;
      //  // max-width: 240px;
      //}

      .username_td,
      .username_th {
        width: 170px;
        // max-width: 240px;
      }

       .phone_th,
       .phone_td {
         // width: 135px;
         width: 100px;
       }

      .status_th,
      .status_td,
      .locked_th,
      .locked_td,
      .admin_th,
      .admin_td {
        width: 80px;
      }

      .roles_th,
      .roles_td {
        width: 300px;
      }

      th input[type="checkbox"],
      td input[type="checkbox"] {
        margin: 0px;
      }

      // .created_date_td,
      // .created_date_th,
      // .modified_date_td,
      // .modified_date_th {
      //   width: 217px;
      // }

      //th {
      //  .plus_icon {
      //    width: 50px;
      //  }
      //}

      .sortable {
        user-select: none;

        &:hover {
          cursor: pointer;
        }
      }

      .sorted {
        app-svg-icon {
          background: #1D7EDA !important;
        }
      }

      .table_menu_th, .table_menu_td {
        width: 55px;
        position: sticky;
        right: 0;
        z-index: 1;
      }
      // tbody tr:nth-child(even) .table_menu_td {
      //   background-color: var(--gray-50);
      // }

      tbody tr .table_menu_td {
        background-color: #FFFFFF;
      }
      tbody tr:hover .table_menu_td {
        background: var(--gray-50);
      }

      .table_menu_th{
        z-index: 3;
      }
    }
  }

  .action_bar {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-lg, 12px);
    align-self: stretch;
    border-top: 1px solid var(--Gray-200, #E4E7EC);
  }

  .pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;

    .info {
      color: $label-color;
    }

    .buttons {
      margin: 0 20px;
      border: 1px solid #cbd2d9;
      display: flex;
      align-items: center;
      border-radius: 5px;
      overflow: hidden;

      button {
        border: none;
        background: #fff;
        cursor: pointer;
        border-radius: 0;
        width: 40px;
        height: 40px;
        padding: 10px;

        &:not(:last-child) {
          border-right: 1px solid #cbd2d9;
        }

        &:hover {
          background: #f5f7f7;
        }
      }

      .active {
        background: #f5f7f7;
      }
    }

    .previous_btn,
    .next_btn {
      display: flex;
      align-items: center;
      justify-content: center;

      app-svg-icon {
        width: 6px;
      }
    }

    .row-options {
      &:hover {
        background-color: #f5f7f7;
      }

      cursor: pointer;
      height: 40px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #cbd2d9;
      background: #fff;
      font-size: 14px;
      font-weight: 600;
      color: #59585b;
      background: url("/assets/icons/select_down_arrow.svg") no-repeat;
      background-position: calc(100% - 0.75rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 30px !important;

      &:focus-visible {
        outline: 1px solid #19b5fe;
      }
    }
  }

  .bulk_actions {
    display: flex;
    align-items: center;
    gap: var(--Space-200, 8px);

    .selection_count {
      .count {
        color: var(--gray-700);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 8px;


      button {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: var(--Space-200, 8px);
        border-radius: var(--Radius-200, 8px);
        border: 1px solid #1062B9;
        background: #E2EDFC;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #10437C;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
      }

      .tick {
        app-svg-icon {
          background: #a3a8b4;
          width: 16px;
        }
      }

      .close {
        app-svg-icon {
          background: #a3a8b4;
          width: 12px;
        }
      }

      .lock {
        app-svg-icon {
          background: #a3a8b4;
          width: 13px;
          margin-bottom: 3px;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  padding: var(--Space-200, 8px) 16px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg, 12px);
  align-self: stretch;
  border-top: 1px solid var(--Gray-200, #E4E7EC);

  .button_wrapper_left {
    display: flex;
    align-items: center;
    flex: 1 0 0;
  }

  .rows_per_page{
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Gray-800, #182230);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    select {
      height: 38px;
      width: 71px;
      appearance: none; /* Remove default arrow */
      background: url('/assets/icons/chevron_up.svg') no-repeat right 10px center;
      border: 1px solid var(--Gray-300, #D0D5DD);
      border-radius: 8px;
      background-color: var(--Grays-White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--gray-700);

      text{
        display: flex;
        padding: 0px 2px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .button_wrapper_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    gap: 8px;
  }

  button {
    display: flex;
    padding: var(--Space-200, 8px) var(--spacing-lg, 12px);
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--Radius-200, 8px);
    border: 1px solid var(--Gray-200, #E4E7EC);
    background: var(--Grays-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--gray-700, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    cursor: pointer;

    text {
      display: flex;
      padding: 0px 2px;
      justify-content: center;
      align-items: center;
    }

    &:disabled{
      background: var(--gray-50);
      cursor: not-allowed;
    }
  }

  .page_numbers {
    display: flex;
    align-items: flex-start;
    gap: 2px;


  }

  .page_number {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: var(--Gray-800, #182230);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .active {
    background: var(--gray-50);
  }


}
